import React from "react"
import { graphql } from "gatsby"
import { BlogPostType } from "../components/blog/blogPostSection"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BlogCategoryLabel from "../components/blog/blogCategoryLabel"
import PictureTemplate from "../components/common/pictureTemplate"
import Wave, { WaveType } from "../components/ui/wave"

const BlogPost = (graphCmsBlogPost: any) => {
  const post: BlogPostType = graphCmsBlogPost.data.graphCmsBlogPost

  const categoryColor = post?.blogCategories[0]?.color ?? "grey"

  return (
    <Layout footerClassName={`u-bg-${categoryColor}`}>
      <SEO title={`Blog-post-${post.heading}`} meta={post.seo} />
      <div className="pt-5">
        <section className="container container-small py-5 min-vh-65">
          <article className="pt-3">
            <PictureTemplate {...post.picture} />
            <div className="d-flex pt-3">
              {post.blogCategories.map((bc, i) => (
                <BlogCategoryLabel
                  key={i}
                  category={bc.category}
                  color={bc.color}
                  slug={bc.slug}
                  lead={true}
                />
              ))}
            </div>
            <h1 className="pt-3">{post.heading}</h1>
            <div
              className="pt-3"
              dangerouslySetInnerHTML={{ __html: post.body.html }}
            />
          </article>
        </section>
      </div>
      <Wave className={`u-c-${categoryColor}`} type={WaveType.v6} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostPageQuery($id: String!) {
    graphCmsBlogPost(id: { eq: $id }) {
      body {
        html
      }
      heading
      blogCategories {
        category
        color
        slug
      }
      picture {
        alt
        url
        localFile {
          childrenImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        ogImage {
          url
        }
        focusKeywords
      }
    }
  }
`

export default BlogPost
